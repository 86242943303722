<template>
  <div class="index">
    <Map></Map>

    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getFarmInfoApi } from "@/request/api";

export default {
  components: {
    Map: () => import("@/components/Map.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      setTime1: null,
      spectrogramList: [],
    };
  },
  beforeDestroy() {
    if (this.setTime1) {
      clearTimeout(this.setTime1);
    }
    this.setTime1 = null;
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getFarmInfoApi();
  },
  mounted() {
    this.SET_NAV({ nav: 0 });
    this.setTime1 = setTimeout(() => {
      let left = this.$refs.MyNav.$refs.refLeft[0].offsetLeft + "px";
      sessionStorage.setItem("navLeft", left);
    }, 50);
  },
  methods: {
    ...mapMutations("user", ["SET_NAV"]),
    async getFarmInfoApi() {
      const { code, results } = await getFarmInfoApi({
        manor_id: this.farmID,
      });
      if (code !== 0) return;
      if (!results.spectrogram) return;
      let spectrogram = JSON.parse(results.spectrogram);
      for (const key in spectrogram) {
        let obj = {};
        obj.img = key;
        obj.tip = spectrogram[key];
        this.spectrogramList.push(obj);
      }
    },
    changeMap(type) {
      this.mapType = type;
    },
  },
};
</script>
<style lang="less" scoped>
video::-webkit-media-controls-fullscreen-button {
  display: block !important;
}
.index {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
